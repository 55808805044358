import Cookies from "js-cookie";

export const dispatchResize = () => {
  if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    navigator.appVersion.indexOf("Trident/") > 0
  ) {
    const evt = document.createEvent("UIEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  } else {
    window.dispatchEvent(new Event("resize"));
  }
};

document.addEventListener("DOMContentLoaded", function() {
  const RISK_WARNING_COOKIE = "riskWarningClosed",
    riskWarning = document.querySelector(".riskWarning"),
    triggerList = document.querySelectorAll(".riskWarning .trigger"),
    regulatorName = document.body.getAttribute("data-regulator") || "fca",
    closed = Cookies.get(RISK_WARNING_COOKIE) === regulatorName;

  const isTouchDevice = () =>
    "ontouchstart" in window || navigator.maxTouchPoints > 0;

  if (!riskWarning) {
    return;
  }

  if (!closed || regulatorName === "jsc") {
    riskWarning.classList.add("open");
  }
  // (Full panel clickable)
  if (riskWarning.classList.contains("hide-on-click")) {
    riskWarning.addEventListener("click", function() {
      riskWarning.classList.toggle("open");
      Cookies.set(RISK_WARNING_COOKIE, regulatorName);
    });
  }
  /// for cysec only
  if (
    isTouchDevice() &&
    riskWarning.classList.contains("hide-on-touch-start")
  ) {
    document.addEventListener("touchstart", e => {
      riskWarning.classList.remove("open");
      Cookies.set(RISK_WARNING_COOKIE, regulatorName);
    });
  }

  // for fca
  if (riskWarning.classList.contains("collapse-on-scroll")) {
    document.addEventListener("scroll", function() {
      riskWarning.classList.remove("open");
      Cookies.set(RISK_WARNING_COOKIE, regulatorName);
    });
  }

  if (triggerList) {
    triggerList.forEach(item => {
      item.addEventListener("click", () => {
        Cookies.set(RISK_WARNING_COOKIE, regulatorName);
        riskWarning.classList.toggle("open");
        if (riskWarning.classList.contains("dispatch-resize")) {
          dispatchResize();
        }
      });
    });
  }

  const regulatorLinks = document.querySelectorAll("a[data-regulator]");

  if (regulatorLinks) {
    regulatorLinks.forEach(item => {
      item.addEventListener("click", () => {
        Cookies.remove(RISK_WARNING_COOKIE);
      });
    });
  }
});
