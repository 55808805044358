import throttle from "lodash/throttle";
import "./styles.scss";

const sticky = (el: HTMLElement, offsetEl: number) => {
  const offsetTop =
    (document.documentElement && document.documentElement.scrollTop) ||
    document.body.scrollTop;
  const sticky_class = "am-sticky-fixed";

  if (offsetTop >= offsetEl) {
    if (el.classList.contains(sticky_class)) {
      return;
    }

    el.classList.add(sticky_class);
  } else {
    el.classList.remove(sticky_class);
  }
};

const initSticky = () => {
  const el: HTMLElement | null = document.querySelector(".am-sticky");

  if (el === null) {
    return;
  }

  const offsetEl = el.offsetTop;
  const stickyOnScroll = throttle(() => sticky(<HTMLElement>el, offsetEl), 60);

  window.addEventListener("scroll", stickyOnScroll);
};

window.addEventListener("DOMContentLoaded", initSticky);
