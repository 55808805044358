import throttle from "lodash/throttle";

let stickyOnScroll: any = null;
let observer: any = null;

const sticky = (
  menu: HTMLElement,
  parentEl: HTMLElement,
  el: HTMLElement,
  elBottom: HTMLElement
) => {
  const sticky_class = "am-sticky-under-menu-fixed";
  const offsetTop =
    (document.documentElement && document.documentElement.scrollTop) ||
    document.body.scrollTop;

  const menuHeight = menu.offsetHeight;
  const offsetTopMenu = offsetTop + menuHeight;
  const offsetBottom = elBottom.offsetHeight + elBottom.offsetTop;

  if (
    offsetTopMenu > parentEl.offsetTop &&
    offsetTopMenu < offsetBottom - parentEl.offsetHeight
  ) {
    if (el.classList.contains(sticky_class)) {
      return;
    }

    el.classList.add(sticky_class);
    el.style.top = `${menuHeight}px`;
  } else {
    el.classList.remove(sticky_class);
    el.style.top = "initial";
  }
};

const initStickyUnderMenu = () => {
  const tab = document.querySelector(".active-surface");
  const stickyItem: HTMLElement | null = tab
    ? tab.querySelector(".am-sticky-under-menu")
    : null;
  const menu: HTMLElement | null = document.querySelector(".main-menu");

  if (menu === null || stickyItem === null) {
    return;
  }

  const parentEl = stickyItem.parentElement;
  const bottomEl: HTMLElement | null = tab
    ? tab.querySelector(".am-sticky-under-menu-bottom")
    : null;

  if (parentEl && bottomEl) {
    /***
     * Set min-height for element's parent block
     */
    parentEl.style.minHeight = `${parentEl.offsetHeight}px`;

    /***
     * Init sticky on scroll
     */
    stickyOnScroll = throttle(
      () => sticky(menu, parentEl, stickyItem, bottomEl),
      60
    );

    window.addEventListener("scroll", stickyOnScroll);
  }

  /**
   * Observer for top padding when risk warning is been shown/hidden
   */
  const content = document.querySelector(".content");
  if (content) {
    observer = new MutationObserver(mutations => {
      for (let mutation of mutations) {
        if (mutation.type == "attributes") {
          const contentEl = mutation.target as Node;

          if (!stickyItem.classList.contains("am-sticky-under-menu-fixed")) {
            return;
          }

          stickyItem.style.top = (contentEl as HTMLElement).style.paddingTop;
        }
      }
    });

    observer.observe(content, {
      attributes: true
    });
  }
};

window.addEventListener("DOMContentLoaded", () => {
  initStickyUnderMenu();

  const tabs = document.querySelectorAll(".mdc-tab-scroller__scroll-content");

  if (tabs.length > 0) {
    tabs.forEach(tab => {
      tab.addEventListener("click", () => {
        if (stickyOnScroll !== null) {
          window.removeEventListener("scroll", stickyOnScroll);
          observer.disconnect();
        }

        initStickyUnderMenu();
      });
    });
  }
});
