document.addEventListener("DOMContentLoaded", function() {
  const rw_btns = document.querySelectorAll<HTMLElement>(".am-rw-btn");
  const rw_header = document.querySelector<HTMLElement>(".am-rw-header");
  const rw_body = document.querySelector<HTMLElement>(".am-rw-body");

  if (rw_btns.length && rw_header && rw_body) {
    const toggleRW = () => {
      rw_header.classList.toggle("hide");
      rw_body.classList.toggle("hide");
    };

    const ontouchmove = () => {
      if (rw_body && !rw_body.classList.contains("hide")) {
        toggleRW();
      }

      document.removeEventListener("touchmove", ontouchmove);
    };

    document.addEventListener("touchmove", ontouchmove);

    window.addEventListener("resize", () => {
      if (window.innerWidth >= 768) {
        if (rw_body && rw_body.classList.contains("hide") && rw_header) {
          rw_header.classList.add("hide");
          rw_body.classList.remove("hide");
        }
      }
    });

    rw_btns.forEach((btn: HTMLElement) => {
      btn.addEventListener("click", () => {
        toggleRW();

        if (rw_body && !rw_body.classList.contains("hide")) {
          document.addEventListener("touchmove", ontouchmove);
        }
      });
    });
  }
});
