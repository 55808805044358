import ResizeObserver from "resize-observer-polyfill";
import Cookies from "js-cookie";

document.addEventListener("DOMContentLoaded", () => {
  const el = document.querySelector(".main-menu") as HTMLElement;

  if (!el) {
    return;
  }

  const content = document.getElementById("main-content");
  const resize = new ResizeObserver(([entry]: any) => {
    if (entry && content) {
      el.classList.add("fixed-menu");
      content.setAttribute(
        "style",
        "padding-top:" + entry.contentRect.height + "px"
      );
    }
  });

  resize.observe(el);

  const menuGroups = el.querySelectorAll(".mp-nav > .mp-nav-el");

  document.addEventListener("click", ({ target }: Event) => {
    const openedItem = el.querySelectorAll(".mp-nav-el-open");
    const targetASNode = target as Node | null;

    if (openedItem) {
      openedItem.forEach(item => {
        if (item !== targetASNode && !item.contains(targetASNode)) {
          if (window.innerWidth > 1024) {
            item.classList.remove("mp-nav-el-open");
          }
        }
      });
    }
  });

  if (menuGroups) {
    menuGroups.forEach(item => {
      const arrow = item.querySelector(".mp-nav-arrow");
      let scrolling = false;

      if (arrow) {
        arrow.addEventListener("click", e => {
          e.preventDefault();
          e.stopImmediatePropagation();

          if (item.classList.contains("mp-nav-el-open")) {
            item.classList.remove("mp-nav-el-open");
          } else {
            item.classList.add("mp-nav-el-open");
          }
        });
      }
      window.addEventListener("touchmove", () => {
        if (!scrolling) {
          window.setTimeout(() => {
            scrolling = true;
          }, 150);
        }
      });

      item.addEventListener("mouseenter", e => {
        if (!isTouchDevice()) {
          item.classList.add("mp-nav-el-open");
        }
      });

      item.addEventListener("mouseleave", e => {
        if (!isTouchDevice()) {
          item.classList.remove("mp-nav-el-open");
        }
      });

      item.addEventListener("touchend", evt => {
        const opened = el.querySelectorAll(".mp-nav-el-open");

        if (window.innerWidth > 1024) {
          if (opened) {
            opened.forEach(openedItem => {
              if (openedItem !== item) {
                openedItem.classList.remove("mp-nav-el-open");
              }
            });
          }
        }

        window.addEventListener("resize", () => {
          opened.forEach(item => {
            if (window.innerWidth > 1024) {
              item.classList.remove("mp-nav-el-open");
            }
          });
        });

        if (
          !item.classList.contains("mp-nav-el-open") &&
          item.querySelector(".mp-nav-arrow")
        ) {
          item.classList.add("mp-nav-el-open");
          evt.preventDefault();
        } else if (scrolling && evt.cancelable) {
          evt.preventDefault();
        }

        scrolling = false;
      });
    });
  }

  const closeSidebar = el.querySelectorAll(".mp-close-sidebar-trigger");

  const sideBar = el.querySelector(".mp-tablet-panel > .mp-wrapper");
  if (closeSidebar && sideBar) {
    closeSidebar.forEach(item => {
      item.addEventListener("click", evt => {
        if (sideBar.classList.contains("mp-show-sidebar")) {
          sideBar.classList.remove("mp-show-sidebar");
          document.body.classList.remove("mp-menu-opened");
        }
      });
    });
  }

  const showSidebar = el.querySelectorAll(".mp-sidebar-show-trigger");
  if (showSidebar && sideBar) {
    showSidebar.forEach(item => {
      item.addEventListener("click", evt => {
        evt.stopPropagation();
        if (!sideBar.classList.contains("mp-show-sidebar")) {
          sideBar.classList.add("mp-show-sidebar");
          document.body.classList.add("mp-menu-opened");
        } else {
          sideBar.classList.remove("mp-show-sidebar");
          document.body.classList.remove("mp-menu-opened");
        }
      });
    });
  }

  const acceptCookie = el.querySelector("#accept_cookies");
  const cookieWidget = el.querySelector(".mp-cookies");

  if (acceptCookie && cookieWidget) {
    acceptCookie.addEventListener("click", event => {
      Cookies.set("cookie_widget_darkgrey", "1", { expires: 365 });
      cookieWidget.classList.remove("mp-show-cookies");
    });
  }

  const account_dropdown_trigger = el.querySelector(".mp-account-info-trigger"),
    account_dropdown = el.querySelector(".mp-account-info");
  const profileMenuTrigger = el.querySelector(".mp-sub-dropdown"),
    profileMenuDropdown = el.querySelector(".mp-profile-dropdown");

  if (account_dropdown_trigger && account_dropdown && profileMenuDropdown) {
    account_dropdown_trigger.addEventListener("click", () => {
      if (account_dropdown.classList.contains("mp-account-info-open")) {
        account_dropdown.classList.remove("mp-account-info-open");
        profileMenuDropdown.classList.remove("mp-profile-dropdown-open");
      } else {
        account_dropdown.classList.add("mp-account-info-open");
      }
    });
  }

  if (profileMenuTrigger && profileMenuDropdown) {
    profileMenuTrigger.addEventListener("click", () => {
      if (profileMenuDropdown.classList.contains("mp-profile-dropdown-open")) {
        profileMenuDropdown.classList.remove("mp-profile-dropdown-open");
      } else {
        profileMenuDropdown.classList.add("mp-profile-dropdown-open");
      }
    });
  }
  document.addEventListener("click", ({ target }: Event) => {
    if ((target as Element).classList.contains("mp-account-info-trigger")) {
      return;
    }

    if (!profileMenuDropdown) {
      return;
    }
    if (!account_dropdown) {
      return;
    }

    if (
      target !== account_dropdown &&
      !account_dropdown.contains(target as Node | null)
    ) {
      account_dropdown.classList.remove("mp-account-info-open");
      profileMenuDropdown.classList.remove("mp-profile-dropdown-open");
    }
  });

  function isTouchDevice() {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {
      return false;
    }
  }
});
