document.addEventListener("DOMContentLoaded", function() {
  const closeBtns = document.querySelectorAll(".am-hide-btn");

  if (closeBtns.length > 0) {
    closeBtns.forEach(btn => {
      btn.addEventListener("click", function() {
        const parent = btn.closest(".am-hide-parent");

        if (parent) {
          parent.classList.add("hide");
        }
      });
    });
  }
});
